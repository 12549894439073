import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export type ResourceQueryParam = Partial<
  Record<
    BookingsQueryParams.STAFF | BookingsQueryParams.RESOURCE,
    string | undefined
  >
>;

export const getResourceQueryParam = (
  wixSdkAdapter: WixOOISDKAdapter,
  flowAPI: ControllerFlowAPI,
): ResourceQueryParam => {
  const { experiments } = flowAPI;
  const isResourceQueryParamEnabled = experiments.enabled(
    'specs.bookings.resourceQueryParam',
  );

  if (isResourceQueryParamEnabled) {
    const preSelectedStaff = wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.STAFF,
    );
    if (preSelectedStaff) {
      return { [BookingsQueryParams.STAFF]: preSelectedStaff };
    }

    const preSelectedResource = wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.RESOURCE,
    );
    if (preSelectedResource) {
      return { [BookingsQueryParams.RESOURCE]: preSelectedResource };
    }

    return {};
  } else {
    const preSelectedStaff = wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.STAFF,
    );
    const selectedStaff = preSelectedStaff ? preSelectedStaff : undefined;
    return { [BookingsQueryParams.STAFF]: selectedStaff };
  }
};
